<template>
	<div class="user-page__wrapper">
		<div class="user-page">
			<UserMenu />
			<div v-if="activityChallengeVisible" class="user-page__content page-activitychallenge ">
				<h2>{{ title }}</h2>

				<div class="container">
					<div class="columns is-desktop  is-multiline">
						<div class="column" :class="{ 'is-half-widescreen': isDigital, 'is-two-fifth-widescreen': isHybrid }">
							<div class="box box-user">
								<div class="row-user">
									<div :class="[`place place-${userPosition.user_leaderboard_position}`, { 'is-large': parseInt(userPosition.user_leaderboard_position) > 999 }]">
										{{ playerPlace(userPosition.user_leaderboard_position) }}
									</div>
								</div>

								<div class="row-points">
									<div class="points">{{ totalPoints }}</div>
									<div class="max">
										<inline-svg :src="require('../assets/game.svg')" width="25" /><span>/ {{ maxPoints }} Points</span>
									</div>
								</div>

								<div class="row-progress">
									<ul class="legend">
										<li class="completed">Completed</li>
										<li>Unfinished</li>
									</ul>
									<progress :value="totalPoints" :max="maxPoints" />
								</div>

								<div class="activity-type">{{ type }}</div>
							</div>
						</div>
						<div class="column" :class="{ 'is-half-widescreen': isDigital, 'is-three-fifths-widescreen': isHybrid }">
							<div class="box box-prize has-text-centered">
								<div v-if="isDigital" class="activity-prizes">
									<div>
										<div class="wreath">
											<inline-svg :src="require('../assets/wreath-w.svg')" width="25" />
											<span>1-8</span>
										</div>
										<span
											>1st, 2nd, 3rd, 4th, 5th,<br />
											6th, 7th & 8th position</span
										>
										<div class="voucher">
											<inline-svg :src="require('../assets/giftcard.svg')" width="25" />
											<span>€100</span>
										</div>
										<strong>EAACI Voucher *</strong>
									</div>
									<div>
										<div class="wreath">
											<inline-svg :src="require('../assets/wreath-w.svg')" width="25" />
											<span>9-12</span>
										</div>
										<span>9th, 10th, 11th & 12th position</span>
										<div class="voucher">
											<inline-svg :src="require('../assets/giftcard.svg')" width="25" />
											<span>€50</span>
										</div>
										<strong>EAACI Voucher *</strong>
									</div>
								</div>
								<div v-if="isHybrid" class="activity-prizes">
									<div>
										<div class="wreath">
											<inline-svg :src="require('../assets/wreath-w.svg')" width="25" />
											<span>1-3</span>
										</div>
										<span>1st, 2nd & 3rd position</span>
										<div class="voucher">
											<inline-svg :src="require('../assets/giftcard.svg')" width="25" />
											<span>€150</span>
										</div>
										<strong>EAACI Voucher *</strong>
									</div>
									<div>
										<div class="wreath">
											<inline-svg :src="require('../assets/wreath-w.svg')" width="25" />
											<span>4-8</span>
										</div>
										<span>4th, 5th, 6th, 7th & 8th position</span>
										<div class="voucher">
											<inline-svg :src="require('../assets/giftcard.svg')" width="25" />
											<span>€100</span>
										</div>
										<strong>EAACI Voucher *</strong>
									</div>
									<div>
										<div class="wreath">
											<inline-svg :src="require('../assets/wreath-w.svg')" width="25" />
											<span>9-12</span>
										</div>
										<span>9th, 10th, 11th & 12th position</span>
										<div class="voucher">
											<inline-svg :src="require('../assets/giftcard.svg')" width="25" />
											<span>€50</span>
										</div>
										<strong>EAACI Voucher *</strong>
									</div>
								</div>
							</div>
						</div>

						<div class="column is-half-widescreen">
							<div class="box box-objective">
								<h3>Objective</h3>

								<p>Gain as many points by completing the challenge activities</p>

								<table>
									<thead>
										<tr>
											<th>Objectives</th>
											<th>Complete</th>
											<th>Points</th>
										</tr>
									</thead>
									<tbody>
										<template v-for="category in categoriesInfo">
											<tr
												:key="category.category_type"
												:class="{ 'is-clickable': category.category_description }"
												@click.prevent="category.category_description_visible = !category.category_description_visible"
											>
												<td>
													<div class="objective-category">
														<div v-if="category.category_description" class="toggler" :class="{ 'is-active': category.category_description_visible }">
															<inline-svg :src="require('../assets/arrow.svg')" width="25" />
														</div>
														<div v-if="category.category_image" class="image">
															<img :src="category.category_image | imageUrl" alt="" />
														</div>
														<div>
															<div>{{ category.category_name }}</div>
															<div v-if="category.category_subtitle" class="subtitle">{{ category.category_subtitle }}</div>
														</div>
													</div>
												</td>
												<td>
													<div class="objective-check" :class="{ 'is-inactive': userPointsByCategoryType(category.category_type) !== category.category_max_points }">
														<inline-svg :src="require('../assets/check.svg')" width="25" />
													</div>
												</td>
												<td>
													<div class="objective-progress" :class="{ 'is-inactive': userPointsByCategoryType(category.category_type) === 0 }">
														<div>{{ userPointsByCategoryType(category.category_type) }}/{{ category.category_max_points }}</div>
														<progress :value="userPointsByCategoryType(category.category_type)" :max="category.category_max_points" />
													</div>
												</td>
											</tr>
											<tr v-if="category.category_description" v-show="category.category_description_visible" :key="`${category.category_type}-info`" class="objective-description">
												<td colspan="3">
													<small v-if="category.category_type === 'scientific_quiz'"
														><inline-svg :src="require('../assets/comment.svg')" width="20" /> Test your knowledge and complete the Scientific Challenge to earn points -
														<a v-if="email" :href="`https://www.surveymonkey.com/r/BWCW5CD?username=${email}`" target="_blank">enter here</a></small
													>
													<small v-else-if="category.category_type.startsWith('daily_scientific_challenge')"
														><inline-svg :src="require('../assets/comment.svg')" width="20" /> {{ category.category_description }} -
														<router-link :to="{ name: 'ScientificChallenges' }">enter here</router-link></small
													>
													<small v-else><inline-svg :src="require('../assets/comment.svg')" width="20" /> {{ category.category_description }}</small>
												</td>
											</tr>
										</template>
									</tbody>
								</table>
							</div>
						</div>

						<div class="column is-half-widescreen">
							<div class="box box-leaderboard">
								<h3>Leaderboard</h3>

								<ul>
									<li v-for="(player, index) in leaderboard" :key="index" :class="{ 'is-user': index + 1 === userPosition.user_leaderboard_position }">
										<div :class="`place place-${index + 1}`">{{ playerPlace(index + 1) }}</div>
										<div class="name" :class="{ 'is-strong': index < 12 }">{{ player.firstname }} {{ player.lastname }}</div>
										<div class="points"><inline-svg :src="require('../assets/game.svg')" width="25" /> {{ player.total_points }}</div>
									</li>
									<li v-if="userPosition.user_leaderboard_position > leaderboard.length" class="is-user">
										<div :class="[`place place-${userPosition.user_leaderboard_position}`, { 'is-large': parseInt(userPosition.user_leaderboard_position) > 999 }]">
											{{ playerPlace(userPosition.user_leaderboard_position) }}
										</div>
										<div class="name">{{ userPosition.firstname }} {{ userPosition.lastname }}</div>
										<div class="points"><inline-svg :src="require('../assets/game.svg')" width="25" /> {{ totalPoints }}</div>
									</li>
								</ul>

								<hr />

								<div>
									<small>
										In case multiple participants of the EAACI Activity Challenge reach a tie with the most points overall, the winner will automatically be identified based on the
										submission time of first completing the full Activity Challenge.
									</small>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div v-else class="user-page__content">
				<p class="notification">Activity challenge is not enabled.</p>
			</div>
		</div>
	</div>
</template>

<script>
	import $http from '../utils/http.js';
	import platformSettings from '../utils/platformSettings';
	import session from '../utils/session';
	import UserMenu from '../components/UserMenu';

	export default {
		name: 'ActivityChallenge',
		components: { UserMenu },
		data() {
			return {
				session: session.state,
				title: null,
				subtitle: null,
				background: null,
				categoriesInfo: [],
				leaderboard: [],
				totalPoints: null,
				userPoints: [],
				userPosition: {},
				userImage: {},
				type: null,
				platformSettings: platformSettings.state,
			};
		},

		beforeRouteEnter(to, from, next) {
			$http.all([$http.get('page-activity-challenge'), $http.get(`activity-challenge`)]).then(
				$http.spread((page, game) => {
					next(vm => {
						vm.setPageData(page);
						vm.setGameData(game);
					});
				})
			);
		},

		beforeRouteUpdate(to, from, next) {
			$http.all([$http.get('page-activity-challenge'), $http.get(`activity-challenge`)]).then(
				$http.spread((page, game) => {
					this.setPageData(page);
					this.setGameData(game);
					next();
				})
			);
		},

		computed: {
			backgroundUrl() {
				return this.background && this.background.url ? `url('${this.$options.filters.imageUrl(this.background)}')` : null;
			},

			maxPoints() {
				const points = this.categoriesInfo.map(({ category_max_points }) => category_max_points) || [];
				return points.reduce((a, b) => a + b, 0);
			},
			email() {
				return this.session.user.username;
			},
			isDigital() {
				return this.type && this.type.toLowerCase() === 'digital';
			},
			isHybrid() {
				return this.type && this.type.toLowerCase() === 'hybrid';
			},
			activityChallengeVisible() {
				return this.platformSettings && this.platformSettings.gamification_enabled;
			},
		},

		methods: {
			playerPlace(place) {
				if (place === 1) return `1st`;
				if (place === 2) return `2nd`;
				if (place === 3) return `3rd`;
				if (place >= 4 && place <= 12) return `${place}th`;
				return `${place}.`;
			},

			setPageData({ data }) {
				this.title = data.title;
				this.subtitle = data.subtitle;
				this.background = data.background;
			},

			setGameData({ data }) {
				this.categoriesInfo = data.categoriesInfo
					.map(category => {
						return {
							...category,
							category_description_visible: false,
							category_image: {
								url: category.category_image_logo,
								provider: category.category_image_provider,
							},
						};
					})
					.sort((a, b) => (b.category_order !== null) - (a.category_order !== null) || a.category_order - b.category_order);
				this.type = data.gamificationType;
				this.leaderboard = data.leaderboard;
				this.totalPoints = data.totalPoints;
				this.userPoints = data.userPoints;
				this.userPosition = data.userPosition;
				this.userImage = data.userImage;
			},

			userPointsByCategoryType(categoryType) {
				const category = this.userPoints.find(({ category }) => category === categoryType) || {};
				return category.user_points || 0;
			},
			updateName() {
				$http.get(`activity-challenge`).then(game => {
					this.setGameData(game);
				});
			},
		},
	};
</script>
